import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgwWowService } from 'ngx-wow';
import { PreloaderService } from '../app/preloader.service';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import { NavigationEnd, Router } from '@angular/router';


gsap.registerPlugin(ScrollTrigger);
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	providers: [
		Location, {
			provide: LocationStrategy,
			useClass: PathLocationStrategy
		}
	]
})

export class AppComponent implements OnInit {
	isFooterVisible: boolean = false;
	location: any;

	constructor( 
		private wowService: NgwWowService,
		private preloader: PreloaderService,
		private router: Router
	){
		this.wowService.init();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
			  	// Delay footer visibility until route fully loads
				setTimeout(() => {
					this.isFooterVisible = true;
				});
			}
		});
	}

  	ngOnInit() {
	}

	ngAfterViewInit() {
		// setTimeout(() => {
		// 	this.isFooterVisible = true;
		// }, 5000);
		
		// this.preloader.hide();
		// this.preloader.show();
	}
}
