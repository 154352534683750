<div *ngIf="!isMobile">
	<header *ngIf="!hideHeader" id="header" class="header" >
		<div id="navbar" class="startp-nav">
			<nav class="navbar navbar-expand-md py-0 navbar-light">
				<div class="container-fluid mx-0 px-0">
					<a class="navbar-brand px-2" routerLink="/">
						<!-- <img src="/assets/img/webp/homepage/proweb-logo.webp" width="241" height="60" alt="logo" class="rpa-logo"/> -->
						<img src="/assets/img/webp/homepage/prowebLogo1.webp" width="241" height="60" alt="logo" class="rpa-logo"/>
					</a>
					<button
					class="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
					(click)="toggleNavbar()"
					>
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{ active: headerShow }">
						<ul class="navbar-nav nav ml-auto">
							<li (click)="toggleNavbar()" class="nav-item">
								<a routerLink="/" class="nav-link">Home</a>
							</li>
							<li (click)="toggleNavbar()" class="nav-item">
								<a routerLink="/about-us" class="nav-link"> About Us </a>
							</li>
							<ul class="navbar-nav">
								<li class=" dropdown"  
								(mouseenter)="toggleDropdown(true)" 
								(mouseleave)="toggleDropdown(false)"
								>
									<a 
								(click)="toggleDropdown(!isDropdownOpen)"
									class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										Services
									</a>
									<ul 
									[class.show]="isDropdownOpen"
									class="dropdown-menu py-2 px-3" aria-labelledby="navbarDarkDropdownMenuLink">
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/web-development-company/dubai" (click)="toggleDropdown(false)">Web Development</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/web-design-company/dubai" (click)="toggleDropdown(false)">Web Design</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/ecommerce-development-company/dubai" (click)="toggleDropdown(false)">E-Commerce Development</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/mobile-app-development-company/dubai" (click)="toggleDropdown(false)">Mobile App Development</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/web-hosting-company/dubai"  (click)="toggleDropdown(false)">Web Hosting</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/blockchain-development-company/dubai" (click)="toggleDropdown(false)">Blockchain Development</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/ai-development/dubai" (click)="toggleDropdown(false)">AI Development</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/machine-learning/dubai" (click)="toggleDropdown(false)">Machine Learning</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/software-quality-assurance/dubai" (click)="toggleDropdown(false)">Quality Assurance</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/seo-company/dubai" (click)="toggleDropdown(false)">Search Engine Optimization</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/adwords-agency/dubai" (click)="toggleDropdown(false)">Google Ads</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/social-media-marketing-company/dubai" (click)="toggleDropdown(false)">Social Media Marketing</a></li>
									</ul>
								</li>
							</ul>
							<!-- <ul class="navbar-nav">
								<li 
								class="nav-item dropdown"   
								[class.show]="dropdownOpen"
								(mouseenter)="openDropdown()"
								(mouseleave)="closeDropdown()" 
								>
									<a (mouseenter)="openDropdown()" [class.show]="dropdownOpen" class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" 
									[attr.aria-expanded]="dropdownOpen" (click)="toggleDropdown($event)">
										Services
									</a>
									<ul class="dropdown-menu py-2 px-3" aria-labelledby="navbarDarkDropdownMenuLink">
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/web-development-company/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">Web Development</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/web-design-company/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">Web Design</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/ecommerce-development-company/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">E-Commerce Development</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/mobile-app-development-company/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">Mobile App Development</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/web-hosting-company/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">Web Hosting</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/blockchain-development-company/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">Blockchain Development</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/ai-development/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">AI Development</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/machine-learning/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">Machine Learning</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/software-quality-assurance/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">Quality Assurance</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/seo-company/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">Search Engine Optimization</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/adwords-agency/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">Google Ads</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/social-media-marketing-company/dubai" (click)="closeDropdownOnOptionClick()"  [class.isdisabled]="!dropdownOpen">Social Media Marketing</a></li>
									</ul>
								</li>
							</ul> -->
							
							<!-- < removed Packages> -->
							<!-- <ul class="navbar-nav">
								<li class="nav-item dropdown">
									<a class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										Packages
									</a>
									<ul class="dropdown-menu py-2 px-3" aria-labelledby="navbarDarkDropdownMenuLink">
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/seo-packages">SEO Packages</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/ppc-packages">PPC Packages</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/smm-packages">SMM Packages</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/website-maintenance-packages">Web Maintenance Packages</a></li>
										<li class="my-3"><a class="dropdown-item m-0" routerLink="/web-hosting-packages">Web Hosting Packages</a></li>
									</ul>
								</li>
							</ul> -->
							<li (click)="toggleNavbar()" class="nav-item">
								<a routerLink="/case-study" class="nav-link">Case Studies </a>
							</li>
							<li (click)="toggleNavbar()" class="nav-item">
								<a routerLink="/portfolio" class="nav-link">Our Work</a>
							</li>
							<li (click)="toggleNavbar()" class="nav-item">
								<a routerLink="/hire-dedicated-developers/dubai" class="nav-link">Hire Developers</a>
							</li>
						</ul>
					</div>
					<div class="anchor-wrapper contact-anchor">
						<a routerLink="/contact-us">
							<span>CONTACT US</span>
						</a>
					</div>
					<div class="anchor-wrapper whatsapp-anchor">
						<a target="_blank" aria-label="whatapp" href="https://api.whatsapp.com/send/?phone=971527732125&text&type=phone_number&app_absent=0">
						<!-- <a target="_blank" aria-label="whatapp" href="https://api.whatsapp.com/send/?phone=971526549225&text&type=phone_number&app_absent=0"> -->
							<img src="/assets/img/webp/homepage/whatapp-header.webp" alt="whatapp" srcset=""/>
						</a>
					</div>
					<div class="anchor-wrapper telephone-anchor">
						<a  aria-label="telephone" href="tel:+971528050084">
						<!-- <a target="_blank" aria-label="telephone" href="tel:+971528050084"> -->
							<img src="assets/img/webp/homepage/telephone-header.webp" alt="telephone" srcset="" />
						</a>
					</div>
				</div> 
			</nav>
		</div>
	</header>
</div>

<div *ngIf="isMobile">
	<div class="mob-header" *ngIf="!hideHeader">
		<div class="mobile-logo menuclose">
			<a routerLink="/">
				<img src="assets/img/logo/proweblogo.svg" alt="proweb-logo" />
			</a>
		</div>
		<a href="https://wa.me/971527732125" class="enquiry-mdd-trig" [hidden]="isMenuOpen">
			<img
				src="../../assets/img/header/whatsapp.webp"
				width="25"
				alt=""
				srcset=""
			/>
		</a>
		<div class="mobile-nav">
			<button (click)="toggleMenu()" [ngClass]="{ 'active': isMenuOpen, 'close-menu': !isMenuOpen }" class="toggle-menu">
				<span></span>
			</button>
		</div>
	</div>
	<div id="mobile-menu" [ngClass]="{ 'open': isMenuOpen }">
		<div class="mainmenu">
			<div class="main-nav">
				<ul class="pt-1 pt-sm-4">
					<li class="lg_size menuclose" (click)="onMenuItemClick()">
						<a routerLink="/">Home</a>
					</li>
					<li class="lg_size menuclose" (click)="onMenuItemClick()">
						<a routerLink="/about-us">About Us</a>
					</li>
					<li (click)="serviceVisibility()">
						<span class="servicemenutag">Services</span>
						<span class="arrow-down"></span>
					</li>
					<!-- removed the pakages -->
					<!-- <li (click)="packageVisibility()">
						<span class="packagemenutag">Packages</span>
						<span class="arrow-down"></span>
					</li> -->
					<li class="menuclose" (click)="onMenuItemClick()">
						<a routerLink="/hire-dedicated-developers/dubai">Hire Developers</a>
					</li>
					<li class="menuclose" (click)="onMenuItemClick()">
						<a routerLink="/case-study">Case Studies</a>
					</li>
					<li class="menuclose" (click)="onMenuItemClick()">
						<a routerLink="/portfolio">Our Work</a>
					</li>
					<li class="menuclose" (click)="onMenuItemClick()">
						<a routerLink="/contact-us">Contact Us</a>
					</li>
				</ul>
				<div class="bottom-header">
					<div class="bottom-content">
						<div class="d-flex justify-content-evenly">
							<div class="partner-img">
								<img
									src="../../assets/img/header/Google_partner.svg"
									style="width: auto; max-width: 100%"
									alt=""
									srcset=""
								/>
							</div>
							<div class="partner-img">
								<img
									src="../../assets/img/header/fb marketing partner.svg"
									width="100%"
									alt=""
									srcset=""
								/>
							</div>
							<div class="partner-img">
								<img
									src="../../assets/img/header/zoho.svg"
									width="100%"
									alt=""
									srcset=""
								/>
							</div>
							<div class="partner-img">
								<img
									src="../../assets/img/header/shopify.svg"
									width="100%"
									alt=""
									srcset=""
								/>
							</div>
						</div>
						<p class="text-white">
							Copyright © 2025 Unisys Technologies L.L.C. All Rights Reserved.
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="servicemenu" [ngClass]="{ myCSSclass: serviceVisible == false }">
			<div class="main-nav orangenav">
				<div class="bottom-header">
					<div class="service-uae-img">
						<img
							src="../../assets/img/header/uae-driving-orange.svg"
							alt=""
							srcset=""
						/>
					</div>
				</div>
				<ul>
					<li>
						<span class="servicehead">Services</span>
						<span
							class="arrow-down"
							(click)="hideService()"
							style="transform: rotate(132deg)"
						></span>
						<ul class="servicenav menuclose">
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/web-design-company/dubai"
									routerLinkActive="active"
									class="nav-link">Web Design</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/web-development-company/dubai"
									routerLinkActive="active"
									class="nav-link">Web Development</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/ecommerce-development-company/dubai"
									routerLinkActive="active"
									class="nav-link">E-Commerce Development</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/mobile-app-development-company/dubai"
									routerLinkActive="active"
									class="nav-link">Mobile App Development</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/web-hosting-company/dubai"
									routerLinkActive="active"
									class="nav-link">Web Hosting</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/blockchain-development-company/dubai"
									routerLinkActive="active"
									class="nav-link">Blockchain Development</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/ai-development/dubai"
								routerLinkActive="active"
								class="nav-link">AI Development</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/machine-learning/dubai"
									routerLinkActive="active"
									class="nav-link">Machine Learning</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/software-quality-assurance/dubai"
									routerLinkActive="active"
									class="nav-link">Quality Assurance</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/seo-company/dubai"
									routerLinkActive="active"
									class="nav-link">Search Engine Optimization</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/adwords-agency/dubai"
									routerLinkActive="active"
									class="nav-link">Google Ads</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/social-media-marketing-company/dubai"
									routerLinkActive="active"
									class="nav-link">Social Media Marketing</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
		<div class="servicemenu" [ngClass]="{ myCSSclass: packageVisible == false }">
			<div class="main-nav orangenav">
				<div class="bottom-header">
					<div class="pckage-uae-img">
					<img
						src="../../assets/img/header/uae-driving-orange.svg"
						alt=""
						srcset=""
					/>
					</div>
				</div>
				<ul>
					<li>
						<span class="packgehead">Packages</span>
						<span
							class="arrow-down"
							(click)="hidePackage()"
							style="transform: rotate(132deg)"
						></span>
						<ul class="servicenav menuclose">
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/seo-packages"
									routerLinkActive="active"
									class="nav-link">SEO Packages</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/ppc-packages"
									routerLinkActive="active"
									class="nav-link">PPC Packages</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/smm-packages"
									routerLinkActive="active"
									class="nav-link">SMM Packages</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/website-maintenance-packages"
									routerLinkActive="active"
									class="nav-link">Web Maintenance Packages
								</a>
							</li>
							<li class="menuItem" (click)="onMenuItemClick()">
								<a routerLink="/web-hosting-packages"
									routerLinkActive="active"
									class="nav-link">Web Hosting Packages</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
  