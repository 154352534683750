<app-scroll-progress-bar></app-scroll-progress-bar>

<app-header class="header-bar"></app-header>

<app-sticky-quoate-animation></app-sticky-quoate-animation>

<router-outlet></router-outlet>

<app-footer class="footer-bar" *ngIf="isFooterVisible"></app-footer> 

