<div #viewport class="viewport">
    <div #content class="content">
      <div class="box">
        <div class="box1">
         <p class="textpBox1" (click)="openModal()"> Get A Quote</p>
        </div>
        
        <div class="box2">
          <div class="wa__btn_popup_txt">
            <span>Need Help? </span>
            <strong>Chat with us</strong>
         </div>
          <a target="_blank" aria-label="whatapp" href="https://api.whatsapp.com/send/?phone=971527732125&text&type=phone_number&app_absent=0">
						<!-- <a target="_blank" aria-label="whatapp" href="https://api.whatsapp.com/send/?phone=971526549225&text&type=phone_number&app_absent=0"> -->
							<!-- <img src="/assets/img/webp/homepage/whatapp-header.webp" alt="whatapp" srcset=""/> -->
              <img src="assets/img/newWhatsaapQuotegreenouter.svg" alt="whatsapp" srcset="" class="newWhatsappClass">
						</a>
        </div>
      </div>
    </div>
  </div>
  <app-quoate-model  *ngIf="isModalOpen" (closeModal)="closeModal()"></app-quoate-model>